<template>
  <div class="_base-w">
    <SubHeader :pageType="pageType" :pageTitle="pageTitle" :isBack="isBack" :isPcTitle="isPcTitle"></SubHeader>
    <div class="_content-w">
      <p class="_marginLL _marginSS-up">LIVE Through（以下，「当サイト」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</p>
      <div class="t_container">
        <p class="t_title">【個人情報】</p>
        <p class="t_text">
          「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，住所，メールアドレスその他の記述等により特定の個人を識別できる情報（個人識別情報）を指します。<br>当サイトは，ユーザーが利用登録をする際に氏名(ハンドルネーム），住所，メールアドレス，などの個人情報をお尋ねすることがあります。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【個人情報を収集・利用する目的】</p>
        <p class="t_text">
          当サイトが個人情報を収集・利用する目的は，以下のとおりです。
        </p>
        <p class="t_text">
          1.当サイトサービスの提供・運営のため
        </p>
        <p class="t_text">
          2.ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当サイトが提供する他のサービスの案内のメールを送付するため
        </p>
        <p class="t_text">
          3.メンテナンス，重要なお知らせなど必要に応じたご連絡のため
        </p>
        <p class="t_text">
          4.利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
        </p>
        <p class="t_text">
          5.ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
        </p>
        <p class="t_text">
          6.上記の利用目的に付随する目的
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【利用目的の変更】</p>
        <p class="t_text">
          1.当サイトは，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
        </p>
        <p class="t_text">
          2.利用目的の変更を行った場合には，変更後の目的について，当サイト所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【個人情報の第三者提供】</p>
        <p class="t_text">
          1.当サイトは，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        </p>
        <p class="t_sub-title">
          (1)人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき<br>
          (2)公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき<br>
          (3)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br>
          (4)予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
        </p>
        <p class="t_sub-title">
          ・利用目的に第三者への提供を含むこと<br>
          ・第三者に提供されるデータの項目<br>
          ・第三者への提供の手段または方法<br>
          ・本人の求めに応じて個人情報の第三者への提供を停止すること<br>
          ・本人の求めを受け付ける方法
        </p>          
        <p class="t_text">
          2.前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
        </p>
        <p class="t_sub-title">
          (1)当サイトが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          (2)合併その他の事由による事業の承継に伴って個人情報が提供される場合
          (3)個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【個人情報の開示】</p>
        <p class="t_text">
          1.当サイトは，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
        </p>
        <p class="t_sub-title">
          (1)本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
          (2)当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
          (3)その他法令に違反することとなる場合
        </p>        
        <p class="t_text">
          2.前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【個人情報の訂正および削除】</p>
        <p class="t_text">
          1.ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当サイトが定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
        </p>
        <p class="t_text">
          2.当サイトは，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
        </p>
        <p class="t_text">
          3.当サイトは，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【個人情報の利用停止等】</p>
        <p class="t_text">
          1.当サイトは，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
        </p>
        <p class="t_text">
          2.前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
        </p>
        <p class="t_text">
          3.当サイトは，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
        </p>
        <p class="t_text">
          4.前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【アクセス解析ツールについて】</p>
        <p class="t_text">
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br>このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは<a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">こちら</a>になります。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【免責事項】</p>
        <p class="t_text">
          当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。<br>
          当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。<br>
          当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【プライバシーポリシーの変更】</p>
        <p class="t_text">
          1.本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
        </p>
        <p class="t_text">
          2.当サイトが別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
        </p>
      </div>
      <div class="t_container">
        <p class="t_title">【お問い合わせ窓口】</p>
        <p class="t_text">
          本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
        </p>
        <p class="t_text">
          LIVE Through<br>
          Mail: <a href="mailto:livethrough.info@gmail.com" class="t_mail">livethrough.info@gmail.com</a>
        </p>
      </div>
      
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue'
import Footer from '@/components/Footer.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  name: 'Music',
  components: {
    SubHeader,
    Footer,
  },
  mixins:[
    Mixin
  ],
  data(){
    return{
      pageType: "",
      pageTitle:"プライバシーポリシー",
      isBack: true,
      isPcTitle: true,



    }
  },
  created(){

  },
  mounted(){
    
  },
  methods:{

  },
  computed:{
  },


}
</script>

<style scoped>

</style>